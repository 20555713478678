































import {Component, Mixins, Watch} from 'vue-property-decorator';
import BecomeAModel from '@/components/BecomeAModel.vue';
import DropDown from '@/components/DropDown.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {PerformersParamsInterface} from '@/types/PerformersParamsInterface';
import {vxm} from '@/store';
import ModelCard from '@/components/ModelCard.vue';
import Loader from '@/components/Loader.vue';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component({
  components: {Loader, ModelCard, BecomeAModel, DropDown},
})
export default class Models extends Mixins(DebounceMixin) {
  orders = {
    resent: 'Recent',
    rate: this.$t('rate'),
    popular: this.$t('popularity'),
    name: this.$t('name'),
  };
  preferences = {
    all: this.$t('allSexualPreferences'),
    gay: this.$t('gay'),
    straight: this.$t('straight'),
    bisexual: this.$t('bisexual'),
  };
  genders = {
    all: this.$t('allGenders'),
    male: this.$t('male'),
    female: this.$t('female'),
    MTF: this.$t('MTF'),
    FTM: this.$t('FTM'),
  };
  params = {
    order: 'popular',
    sexualOrientation: 'all',
    preference: 'all',
    keyword: '',
  };
  page = 1;
  take = 11;
  randomPromoIndex = this.randomInt(4, 12);
  models: ModelDataInterface[] = [];
  totalModels = 0;
  isRequestSend = false;
  searchQuery = '';
  debouncedSearch = this.debounce(this.changeSearch, 700);

  mounted() {
    this.getFirstPage();
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  @Watch('searchQuery')
  onSearchQueryChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  handleScroll() {
    const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (bottomOfWindow && !this.isRequestSend && this.totalModels > this.models.length) {
      this.getModels();
    }
  }

  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  capFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getFirstPage() {
    this.page = 1;
    this.models = [];
    this.getModels();
  }

  getModels() {
    const params: PerformersParamsInterface = {
      status: 'active',
      sort: this.params.order,
      take: this.take,
      page: this.page,
      keyword: this.params.keyword,
      sexualOrientation: this.params.sexualOrientation === 'all' ? '' : this.params.sexualOrientation,
    };
    this.isRequestSend = true;

    vxm.fan
      .getPerformers(params)
      .then((res) => {
        this.models.push(...res.data.items);
        this.totalModels = res.data.count;
        this.page++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
        if (this.totalModels > this.models.length) {
          this.$nextTick(() => {
            const hasVScroll = document.body.scrollHeight > window.innerHeight;
            if (!hasVScroll) {
              this.getModels();
            }
          });
        }
      });
  }

  changeSearch() {
    this.params.keyword = this.searchQuery;
    this.getFirstPage();
  }
}
